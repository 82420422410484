import { ReactNode } from "react";
import styled from "@emotion/styled";

interface CardProps {
  padding?: string;
  backgroundColor?: string;
  border?: string;
  children: ReactNode;
}

export const ContentCard = ({
  padding,
  backgroundColor,
  children,
  border,
}: CardProps) => (
    <CardContainer
      padding={padding}
      backgroundColor={backgroundColor}
      border={border}
    >
      {children}
    </CardContainer>
  );

const CardContainer = styled.div<{
  padding?: string | "16px";
  backgroundColor?: string;
  border?: string | "none";
}>`
  border-radius: 8px;
  padding: ${({ padding }) => padding || "16px"};
  background: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.brand.secondary.default};
  width: 100%;
  height: 100%;
  border: ${({ border }) => border || "none"};
`;
