import { DeltaStatic } from "react-quill-new";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import styled from "@emotion/styled";

interface QuillRendererProps {
  ops: DeltaStatic[];
}

export const QuillRenderer = ({ ops }: QuillRendererProps) => {
  const converter = new QuillDeltaToHtmlConverter(ops, {});

  const html = converter.convert();

  return <EditorWrapper dangerouslySetInnerHTML={{ __html: html }} />;
};

const EditorWrapper = styled.div`
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
  }

  video {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
  }
`;
