// import moment from "moment";
import moment from "moment-timezone";
// import "moment/locale/ko";

export const isEnglishPath = (): boolean => {
  if (typeof window !== "undefined") {
    return window.location.pathname.includes("/en");
  }
  return false;
};

const DATE_FORMATS = {
  ko: {
    withYear: "YYYY년 MM월 DD일(dd)",
    withoutYear: "MM월 DD일(dd)",
    time24: " HH:mm",
    time12: " hh:mm A",
    periodWithYear: "YYYY년 MM월 DD일",
    periodWithoutYear: "MM월 DD일",
  },
  en: {
    withYear: "MMMM DD, YYYY (ddd)",
    withoutYear: "MMMM DD (ddd)",
    time24: " HH:mm",
    time12: " hh:mm A",
    periodWithYear: "MMMM DD, YYYY",
    periodWithoutYear: "MMMM DD",
  },
};

const setMomentLocale = () => {
  const locale = isEnglishPath() ? "en" : "ko";

  moment.locale(locale);
  return locale as keyof typeof DATE_FORMATS;
};

export const suffixes = {
  sessionNumber: {
    ko: (value?: number) => `${value}회차`,
    en: (value?: number) => `Round ${value}`,
  },
  totalSessions: {
    ko: (value?: number) => `(${value}회)`,
    en: (value?: number) => `(Total ${value})`,
  },
  minutes: {
    ko: (value?: number) => `${value}분`,
    en: (value?: number) => `${value} minutes`,
  },
};

const getLocalizedSuffix = (
  key: keyof typeof suffixes,
  value?: number,
): string => {
  const currentLocale = isEnglishPath() ? "en" : "ko";

  return suffixes[key]?.[currentLocale]?.(value) || "";
};

type DateFormat = {
  withYear?: boolean;
  withTime?: boolean; // 시간 포함 여부
  withSessions?: boolean; // 회차 정보 포함 여부
  period?: boolean; // 기간 표시 여부
  use12Hours?: boolean; // 12시간제 사용 여부 (AM/PM 포함)
};

/**
 * 날짜를 포맷팅하는 기본 함수
 * @example
 * - formatDate(date) // "2024년 11월 13일(수)"
 * - formatDate(date, { withTime: true }) // "2024년 11월 13일(수) 09:00"
 * - formatDate(date, { withTime: true, use12Hours: true }) // "2024년 11월 13일(수) 09:00 AM"
 */
export const formatDate = (date: Date, options: DateFormat = {}) => {
  const {
    withYear = true,
    withTime = false,
    period = false,
    use12Hours = false,
  } = options;

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const m = moment(date).tz(userTimezone);
  const currentLocale = setMomentLocale() || "ko";
  moment.locale(currentLocale);
  const formats = DATE_FORMATS[currentLocale];

  // 기간 표시
  if (period) {
    return withYear
      ? m.format(formats.periodWithYear)
      : m.format(formats.periodWithoutYear);
  }

  // 기본 날짜 포맷
  let result = withYear
    ? m.format(formats.withYear)
    : m.format(formats.withoutYear);

  // 시간 포함 여부
  if (withTime) {
    if (use12Hours) {
      result += m.format(formats.time12);
    } else {
      result += m.format(formats.time24);
    }
  }

  return result;
};

/**
 * 수업 일정에 특화된 포맷팅
 * @example
 * formatClassSchedule(start, end, 90) // "2024년 11월 13일(수) 09:00~10:30(90분)"
 */
export const formatClassSchedule = (
  start: Date,
  end: Date,
  durationMinutes?: number,
  sessionNumber?: number,
) => {
  const startStr = formatDate(start, { withTime: true });
  const endTime = moment(end).format("HH:mm");

  let result = `${startStr}~${endTime}`;
  if (durationMinutes) {
    result += ` (${getLocalizedSuffix("minutes", durationMinutes)})`;
  } else if (sessionNumber) {
    result += ` (${getLocalizedSuffix("sessionNumber", sessionNumber)})`;
  }

  return result;
};

/**
 * 수업 기간에 특화된 포맷팅
 * @example
 * formatClassPeriod(start, end, 8) // "2024년 11월 13일~2024년 12월 31일 (8회)"
 */
export const formatClassPeriod = (
  start: Date,
  end: Date,
  totalSessions: number,
  withYear: boolean = true,
) => {
  const startStr = formatDate(start, { period: true, withYear });
  const endStr = formatDate(end, { period: true, withYear });

  return `${startStr}~${endStr} ${getLocalizedSuffix("totalSessions", totalSessions)}`;
};

export const formatClassPeriodWithoutYear = (
  start: Date,
  end: Date,
  totalSessions: number,
) => {
  return formatClassPeriod(start, end, totalSessions, false);
};

export const formatCyclePeriod = (start: Date, end: Date, sessions: number) =>
  formatClassPeriod(start, end, sessions);

export const formatScheduleDateWithTime = (date: Date) =>
  formatDate(date, { withTime: true });

/**
 * 다회차 스케줄 선택란
 * @example
 * formatClassPeriodWithTime(start, end, 2)
 * => "12월 04일~12월 11일(2회) 12:30"
 */
export const formatClassPeriodWithTime = (
  start: Date,
  end: Date,
  totalSessions: number,
  withYear: boolean = false,
) => {
  const startStr = formatDate(start, { period: true, withYear });
  const endStr = formatDate(end, { period: true, withYear });
  const startTime = moment(start).format("HH:mm");

  return `${startStr}~${endStr} ${getLocalizedSuffix(
    "totalSessions",
    totalSessions,
  )} ${startTime}`;
};

export const formatClassStartTime = (date: Date) => {
  return formatDate(date, { withYear: false, withTime: true });
};

// ? [케아클]강의 상세페이지 스케줄 카드
export const formatSingleClassDate = (date: Date) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // ✅ 사용자 브라우저 시간대 감지
  const m = moment(date).tz(userTimezone); // ✅ UTC -> 브라우저 시간대로 변환
  const currentLocale = setMomentLocale() || "ko";
  moment.locale(currentLocale);
  // const m = moment(date);

  const format =
    currentLocale === "ko" ? "MM월 DD일 dd요일 HH:mm" : "MMM DD dddd HH:mm"; // ✅ 한글/영어 포맷 분기
  const formattedDate = m.format(format).split(" ");
  const [mm, dd, day, time] = formattedDate;
  const dates = `${mm} ${dd}`;
  // console.log(userTimezone, formattedDate);
  return { dates, day, time };
};
