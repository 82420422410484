import { ReactNode } from "react";
import styled from "@emotion/styled";
import { Modal } from "@mui/material";

import { useModalStore } from "@/stores";

interface ModalProps {
  modalKey: string;
  children: ReactNode;
  backgroundColor?: string;
}

export const BaseModal = ({
  modalKey,
  children,
  backgroundColor,
}: ModalProps) => {
  const { isModalOpen, closeModal } = useModalStore();

  return (
    <>
      <Modal
        open={isModalOpen(modalKey)}
        onClose={() => closeModal(modalKey)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer backgroundColor={backgroundColor}>
          {children}
        </ModalContainer>
      </Modal>
    </>
  );
};

const ModalContainer = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 24px;
  overflow: auto;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.brand.secondary.default};
`;
