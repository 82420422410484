import { useState } from "react";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { getRemaining } from "@shared/lib/utils/classData";
import {
  formatClassPeriodWithTime,
  formatClassStartTime,
} from "@shared/lib/utils/date/formatSchedule";

import { useAccessToken } from "@/hooks/auth/use-access-token";
import {
  InquiryType,
  useInquiryTypeStore,
  useModalStore,
  useScheduleStore,
} from "@/stores";
import { useCustomTranslation } from "@/utils/useCustomTranslation";

import {
  CycleResponseDto,
  ScheduleResponseDto,
} from "@shared/generated/api/model";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";
import { useRouter } from "next/router";

export const AccordionList = ({
  cycles,
  totalSessions,
}: {
  cycles: CycleResponseDto[];
  totalSessions: number;
}) => {
  const { t } = useCustomTranslation();
  const router = useRouter();
  const { accessToken } = useAccessToken();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  const { setInquiryType } = useInquiryTypeStore();
  const { openModal } = useModalStore();
  const setSchedule = useScheduleStore((state) => state.setScheduleId);
  const clearSchedule = useScheduleStore((state) => state.clearScheduleId);

  const toggleItem = (index: number, cycleId: number, isClosed: boolean) => {
    {
      if (openIndex === index) {
        setOpenIndex(null);
        clearSchedule();
      } else {
        setOpenIndex(index);
        isClosed ? clearSchedule() : setSchedule(cycleId);
      }
    }
  };

  const handleScheduleInquiry = () => {
    if (accessToken) {
      setInquiryType(InquiryType.Schedule);
      openModal("inquiryModal");
    } else {
      setAlertModalTexts({
        type: ALERT_MODAL_TYPE.ERROR,
        title: t("login_required"),
        subTitle: "",
      });
      setIsAlertModalOpen(true);
    }
  };
  // console.log(cycles);
  return (
    <ListContainer>
      {isAlertModalOpen && (
        <AlertModal
          {...alertModalTexts}
          isOpen={isAlertModalOpen}
          onClose={() => setIsAlertModalOpen(false)}
          onConfirm={() => router.push(`/login?callbackUrl=${router.asPath}`)}
          confirmText={t("signin")}
        />
      )}
      {cycles.map((cycle, index) => (
        <ListWrapper
          key={index}
          onClick={() => toggleItem(index, cycle.id, cycle.enrollmentClosed)}
          isOpen={!cycle.enrollmentClosed && openIndex === index}
          disabled={cycle.enrollmentClosed || !cycle.selling}
        >
          <ListItem>
            <ScheduleText
              isOpen={!cycle.enrollmentClosed && openIndex === index}
              disabled={cycle.enrollmentClosed || !cycle.selling}
            >
              {formatClassPeriodWithTime(
                new Date(cycle.startsAt),
                new Date(cycle.endsAt),
                totalSessions,
              )}
              <RemainText>
                {cycle.enrollmentClosed || !cycle.selling
                  ? t("closed")
                  : `${getRemaining(
                      cycle.maxEnrollments,
                      cycle.cycleEnrollments.length,
                    )}${t("spots_remaining")}
                  `}
              </RemainText>
            </ScheduleText>
            <Icon
              icon={
                openIndex === index
                  ? ICON_SVG.LINE.SIZE_16.ICON_ARROW_UP
                  : ICON_SVG.LINE.SIZE_16.ICON_ARROW_DOWN
              }
            />
          </ListItem>
          {cycle.schedules && openIndex === index && (
            <>
              <SplitLine />
              <SubList>
                {(cycle.schedules as ScheduleResponseDto[]).map(
                  (subItem, subIndex) => (
                    <SubListItem
                      key={subIndex}
                      disabled={cycle.enrollmentClosed || !cycle.selling}
                    >
                      {formatClassStartTime(new Date(subItem.startsAt))}
                      <Description>{subItem.session.title}</Description>
                    </SubListItem>
                  ),
                )}
              </SubList>
            </>
          )}
        </ListWrapper>
      ))}
      <AddScheduleCard onClick={handleScheduleInquiry}>
        {t("schedule_consultation").replaceAll("\\n", "\n")}
      </AddScheduleCard>
    </ListContainer>
  );
};

const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  width: 100%;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListWrapper = styled.div<{ isOpen: boolean; disabled: boolean }>`
  padding: 16px;
  border-radius: 8px;
  border: ${({ disabled, isOpen, theme }) =>
    disabled
      ? `1px solid ${theme.colors.gray.gray20}`
      : isOpen
        ? `2px solid ${theme.colors.icon.active.white}`
        : `1px solid ${theme.colors.icon.inactive.white}`};
`;

const ListItem = styled.li`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScheduleText = styled.div<{ isOpen: boolean; disabled: boolean }>`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ disabled, isOpen, theme }) =>
    disabled
      ? theme.colors.gray.gray20
      : isOpen
        ? theme.colors.icon.active.white
        : theme.colors.icon.inactive.white};
`;

const RemainText = styled.span`
  ${({ theme }) => theme.typography.body2.r};
  color: ${({ theme }) => theme.colors.state.error.default};
  padding-left: 8px;
`;

const SplitLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.icon.active.white};
  padding-top: 16px;
`;

const SubList = styled.ul`
  list-style: none;
  max-height: 260px;
  overflow-y: scroll;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  scrollbar-width: thin;
  scrollbar-color: #7a7a7a #e8e8e8; /* Foreground and background color for scrollbar */

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #e8e8e8;
    border-radius: 100px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 100px !important;
  }
`;

const SubListItem = styled.li<{ disabled: boolean }>`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray.gray20 : theme.colors.text.high.white};
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const AddScheduleCard = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;
