import { useState } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import styled from "@emotion/styled";

import "react-phone-input-2/lib/style.css";

interface PhoneData {
  countryCode: string;
  phoneNumber: string;
}

interface GlobalPhoneNumberInputProps {
  onChange: (data: PhoneData) => void;
  disabled?: boolean;
  errorMessage?: string;
}

export const GlobalPhoneNumberInput = ({
  onChange,
  disabled = false,
  errorMessage = "",
}: GlobalPhoneNumberInputProps) => {
  const [countryCode, setCountryCode] = useState<string>("82");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handlePhoneChange = (fullValue: string, countryData: CountryData) => {
    const dialCode = countryData.dialCode;
    const numberOnly = fullValue.slice(dialCode.length);
    // fullValue.startsWith(`+${dialCode}`)
    //   ? fullValue.slice(dialCode.length + 1)
    //   : fullValue.trim();

    setCountryCode(dialCode);
    setPhoneNumber(numberOnly);

    // console.log(dialCode, numberOnly);
    onChange({ countryCode: dialCode, phoneNumber: numberOnly });
  };

  return (
    <Container>
      <StyledPhoneInput
        country={"us"}
        enableSearch={true}
        // value={`+${countryCode}${phoneNumber}`}
        onChange={handlePhoneChange}
        disabled={disabled}
        inputProps={{
          required: true,
          autoFocus: false,
        }}
      />
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    ${({ theme }) => theme.typography.body1.m};
    border: 1px solid ${({ theme }) => theme.colors.gray.gray80} !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    width: 100% !important;
    height: 50px !important;
    padding-left: 52px !important;
  }
  .from-control.open {
    border-radius: 3px !important;
  }

  .flag-dropdown {
    border-radius: 3px 0 0 3px !important;
    border-right: none !important;
  }

  .flag-dropdown.open .selected-flag {
    border-radius: 3px !important;
  }

  .selected-flag {
    background: white;
    padding-left: 16px !important;
  }
  .selected-flag .flag {
    margin-top: -6px !important;
  }

  .country-list {
    border-radius: 4px !important;
    border: 1px solid ${({ theme }) => theme.colors.gray.gray80} !important;
    width: 238px !important;
    max-height: 260px !important;
    margin: 8px 0 !important;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15) !important;
    left: -1px !important;
  }

  .no-entries-message {
    padding: 8px 16px !important;
  }

  .country {
    padding: 8px 16px !important;
    letter-spacing: -0.04px;
  }

  .search-emoji {
    display: none !important;
  }

  .search {
    padding: 12px 16px !important;
  }

  .search-box {
    width: 182px !important;
    margin: 0 !important;
    padding: 8px 10px 8px !important;
  }

  .country:hover {
    background-color: ${({ theme }) => theme.colors.gray.gray95} !important;
  }
`;

const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.state.error.default};
  font-size: 12px;
`;
